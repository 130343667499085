<template>
  <div>
    <b-overlay :show="loading">
      <b-card>
        <app-collapse accordion>
          <app-collapse-item title="Información Básica">
            <b-row>
              <b-col cols="4">
                <div class="mt-md-0 mt-2">
                  <table class="mt-2 mt-xl-0 w-100 text-left">
                    <tr>
                      <th class="pb-50">
                        <span>Rut: </span>
                        <span class="font-weight-bold">{{ value.rut_empresa ? value.rut_empresa:'-' }}</span>
                      </th>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <span>Giro: </span>
                        <span class="font-weight-bold">{{ value.giro_empresa ? value.giro_empresa:'-' }}</span>
                      </th>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <span>Nombre Fantasía: </span>
                        <span class="font-weight-bold">{{ value.nombre_fantasia ? value.nombre_fantasia:'-' }}</span>
                      </th>
                    </tr>
                  </table>
                  <div />
                </div>
              </b-col>
              <b-col cols="4">
                <div class="mt-md-0 mt-2">
                  <table class="mt-2 mt-xl-0 w-100 text-left">
                    <tr>
                      <th class="pb-50">
                        <span>Dirección Principal: </span>
                        <span class="font-weight-bold">{{ value.direccion_empresa ? value.direccion_empresa:'-' }}</span>
                      </th>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <span>Razón Social: </span>
                        <span class="font-weight-bold">{{ value.razon_social_empresa ? value.razon_social_empresa:'-' }}</span>
                      </th>
                    </tr>
                  </table>
                  <div />
                </div>
              </b-col>
            </b-row>

          </app-collapse-item>
          <app-collapse-item title="Información Facturación">
            <b-row>
              <b-col cols="4">
                <div class="mt-md-0 mt-2">
                  <table class="mt-2 mt-xl-0 w-100 text-left">
                    <tr>
                      <th class="pb-50">
                        <span>D.Factura: </span>
                        <span class="font-weight-bold">{{ value.rut_empresa ? value.rut_empresa:'-' }}</span>
                      </th>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <span>C. Factura: </span>
                        <span class="font-weight-bold">{{ value.rut_empresa ? value.rut_empresa:'-' }}</span>
                      </th>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <span>R. Factura: </span>
                        <span class="font-weight-bold">{{ value.rut_empresa ? value.rut_empresa:'-' }}</span>
                      </th>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <span>D.Despacho: </span>
                        <span class="font-weight-bold">{{ value.rut_empresa ? value.rut_empresa:'-' }}</span>
                      </th>
                    </tr>
                  </table>
                  <div />
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Información SII">
            <b-row>
              <b-col cols="4">
                <div class="mt-md-0 mt-2">
                  <table class="mt-2 mt-xl-0 w-100 text-left">
                    <tr>
                      <th class="pb-50">
                        <span>Mail SII: </span>
                        <span class="font-weight-bold">{{ value.mail_sii ? value.mail_sii:'-' }}</span>
                      </th>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <span>Obs. Glosa Fac: </span>
                        <span class="font-weight-bold">{{ value.rut_empresa ? value.rut_empresa:'-' }}</span>
                      </th>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <span>Requiere Orden Compra: </span>
                        <span class="font-weight-bold">{{ value.requiere_orden_compra ? value.requiere_orden_compra:'-' }}</span>
                      </th>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <span>Req. Hess: </span>
                        <span class="font-weight-bold">{{ value.rut_empresa ? value.rut_empresa:'-' }}</span>
                      </th>
                    </tr>
                  </table>
                  <div />
                </div>
              </b-col>
              <b-col cols="4">
                <div class="mt-md-0 mt-2">
                  <table class="mt-2 mt-xl-0 w-100 text-left">
                    <tr>
                      <th class="pb-50">
                        <span>Req. N° contrato: </span>
                        <span class="font-weight-bold">{{ value.requiere_numero_contrato ? value.requiere_numero_contrato:'-' }}</span>
                      </th>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <span>Nombre c.Cobranza: </span>
                        <span class="font-weight-bold">{{ value.nombre_contacto_cobranza ? value.nombre_contacto_cobranza:'-' }}</span>
                      </th>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <span>Requiere OTA: </span>
                        <span class="font-weight-bold">{{ value.requiere_ota ? value.requiere_ota:'-' }}</span>
                      </th>
                    </tr>
                  </table>
                  <div />
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </b-card>
      <b-card>
        <h1>Datos</h1>
        <app-echart-line :option-data="graficos" />
        <hr>
        <b-card>
          <h4 class="card-title">
            Listado Ventas

          </h4>
          <b-row class="pr-1 pl-1">
            <b-col
              sm="12"
              md="6"
              class="my-1 text-right"
            >
              <div class="d-flex align-items-center mb-1 mb-md-0">
                <label>Mostrar</label>
                <b-form-select
                  id="perPageSelect1"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                  class="mx-50 col-md-2"
                  @change="onPerPage"
                />
                <label>filas por página</label>
              </div>
            </b-col>
          </b-row>
          <b-col
            v-if="showTable"
            cols="12"
          >
            <b-table
              id="my-table"
              ref="table"
              striped
              hover
              small
              :per-page="perPage"
              :items="ventas"
              :fields="filteredHeaders"
              responsive
              class="mb-0"
              show-empty
            >
              <template #empty="scope">
                <div class="text-center">
                  No existen resultados
                </div>
              </template>
            </b-table>
          </b-col>

          <b-row class="pl-1">
            <b-col
              cols="8"
              class="mt-3"
            >
              <div v-if="totalRows > 0">
                <span class="text-muted">  Mostrando del {{ from }} al {{ to }} de {{ totalRows }} filas</span>
              </div>
            </b-col>

            <b-col cols="4">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :value="page"
                :per-page="perPage"
                align="right"
                class="mt-3 mr-1"
                aria-controls="my-table"
                @change="onPageChange"
              />
            </b-col>

          </b-row>
        </b-card>
      </b-card>
    </b-overlay>
  </div>

</template>

<script>

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'
import {
  BButton,
  BCol,
  BFormFile,
  BFormSelect,
  BPagination,
  BRow, BSpinner,
  BTable,
} from 'bootstrap-vue'

export default {
  name: 'DetalleEmpresa',
  components: {
    AppCollapse,
    AppCollapseItem,
    BTable,
    BRow,
    BCol,
    BFormSelect,
    BButton,
    BFormFile,
    BPagination,
    AppEchartLine,
  },
  data() {
    return {
      grafico: {
        series: [],
        xAxisData: [],
      }, // precio
      idEmpresa: 0,
      showTable: true,
      perPage: 50,
      reportType: 1,
      currentPage: 1,
      page: 1,
      pageOptions: [50, 100, 500, 1000],
      filter: {
        id_empresa: null,
        item_per_page: 50,
        page: 1,
      },
      fields: [
        { key: 'num_ficha', label: 'N° de Ficha', display: true },
        { key: 'curso', label: 'Nombre del Curso', display: true },
        { key: 'categoria', label: 'Tipo de Venta', display: true },
        { key: 'fecha_inicio', label: 'Fecha de Venta', display: true },
        { key: 'valor_cobrado', label: 'Total Venta', display: true },
      ],
      option2: {
        valor_cobrado: [], // ventas diagonal
        fecha_inicio: [], // fechas para arriba
      },
      listaCodigo: [],

    }
  },
  computed: {
    config() {
      return {
        locale: require('flatpickr/dist/l10n/es.js').default.es,
        dateFormat: 'd-m-Y',
        wrap: true,
      }
    },
    totalRows() {
      return this.$store.state.empresa.rowsTotal
    },
    to() {
      return this.$store.state.empresa.to
    },
    from() {
      return this.$store.state.empresa.from
    },
    ventas() {
      return this.$store.state.empresa.detalleEmpresaVenta
    },
    filteredHeaders() {
      return this.fields.filter(h => h.display === true)
    },
    loading() {
      return this.$store.state.empresa.loading
    },
    isBusy() {
      return this.$store.state.empresa.isBusy
    },
    value() {
      return this.$store.state.empresa.detalleCompletoEmpresa
    },
    graficos() {
      let grafico = {
        series: [],
        xAxisData: [],
      }
      const graficoCantidad = this.$store.state.empresa.detalleEmpresaVenta
      for (let i = 0; i < graficoCantidad.length; i++) {
        const fechas = (this.$store.state.empresa.detalleEmpresaVenta[i].fecha_inicio)
        const final = fechas.slice(5)
        grafico.xAxisData.push(final.replace('-', '/'))
        grafico.series.push(this.$store.state.empresa.detalleEmpresaVenta[i].valor_cobrado)
      }
      console.log("DATOSSSSSS GRAFICOSSSSSSSSSSSSSSSSSSSSSSS",grafico);
      return grafico
    },

  },
  mounted() {
    this.filter.id_empresa = this.$route.params.id
    this.$store.dispatch('empresa/getDetalleCompletoEmpresa', this.filter)
    this.$store.dispatch('empresa/getDetalleEmpresaVentas', this.filter)
    this.graficos
  },
  methods: {
    onPageChange(page) {
      console.log("onPageChange",page);
      this.page = page
      this.filter.page = page
    },
    onChangePerPage() {
      this.page = 1
    },
    onPerPage() {
      this.filter.rowsPerPage = this.perPage
    },


  },
}
</script>

<style scoped>

</style>
